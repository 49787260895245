import React, { act, useContext, useEffect, useState } from "react";
import okxIcon from "../../../../media/okxIconForModal.png";
import creditCoinIcon from "../../../../media/silver-pepe-bg.png";

import "./okx.css";
import { UserContext } from "../../../../context/UserContext";
import { okxTaskId, SERVER_ENDPOINT } from "../../../../utils/constants";
import { AuthContext } from "../../../../context/AuthContext";
import toast from "react-hot-toast";

import { WalletContext } from "../../../../context/WalletContext";

const OkxModal = () => {
  const { setUserCredits } = useContext(UserContext);
  const { activeWallet, handleOkxConnect } = useContext(WalletContext);

  const { jwt } = useContext(AuthContext);
  const [taskID, setTaskID] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchTasks() {
      try {
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen: "okx" }),
        });
        const parsedResponse = await response.json();
        setTaskID(parsedResponse?.data[0]?.taskID);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTasks();
  }, []);

  useEffect(() => {
    async function handleTaskCompletion(taskID) {
      try {
        setIsLoading(true);
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/redeem`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ okxTaskId }),
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS") {
          setUserCredits(
            (credits) => credits + parseInt(parsedResponse.data.reward)
          );
          toast.success("Task Completed");
        } else throw new Error("Failed to redeem task");
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (activeWallet === "okx" && taskID) handleTaskCompletion(taskID);
  }, [activeWallet, taskID]);

  return (
    <div className="okx_container">
      <div
        style={{
          position: "relative",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "-5%",
            right: "-15%",
            color: "white",
            background: "red",
            padding: "3px 8px",
            borderRadius: "10px",
            fontFamily: "Roboto",
            fontSize: "15px",
          }}
        >
          NEW
        </span>
        <img src={okxIcon} alt="" className="okxIconImg" />
      </div>
      <div>
        <span
          style={{
            fontSize: "18px",
            color: "white",
            fontFamily: "Roboto",
          }}
        >
          The World’s Most Powerful Web3 Portal
        </span>
        <p
          style={{
            fontSize: "25px",
            fontWeight: "800",
            fontFamily: "Roboto",
            color: "#FFD000",
            padding: "5px 20px",
            margin: "0px",
          }}
        >
          {activeWallet === "okx"
            ? "THANK YOU FOR COMPLETING THE TASK"
            : "CONNECT OKX WALLET AND WIN BIG"}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          margin: "30px auto",
        }}
      >
        <span
          style={{
            fontSize: "25px",
            fontWeight: "800",
            fontFamily: "Roboto",
            color: "#FFD000",
          }}
        >
          {" "}
          +1,000,000{" "}
        </span>
        <span>
          <img src={creditCoinIcon} alt="" className="okxSilverCoin" />
        </span>
      </div>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        {activeWallet === "okx" ? (
          <button disabled={true} className="stripe-button">
            Connected
          </button>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <button onClick={handleOkxConnect} className="stripe-button">
              Connect Okx Now
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OkxModal;
