import React, { useContext, useEffect } from "react";
import "./BecomeBoss.css";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import v3_goldCoin from "../../../media/v3_goldCoin.png";
import { FaStar } from "react-icons/fa6";
import { MdDoubleArrow } from "react-icons/md";
import v3_energyIcon from "../../../media/v3_energyIcon.png";
import v3_boostIcon from "../../../media/v3_boostIcon.png";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import { ClubContext } from "../../../context/ClubContext";
import {
  userClubRankToStarsMapping,
  userClubToRankNameMapping,
} from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas";
import creditCoinGif from "../../../media/silver-pepe-bg.png";
import { MotorContext } from "../../../context/MotorContext";
import { HotelContext } from "../../../context/HotelContext";
import useTelegramBackButton from "../../../utils/backButton";

const rankTableData = [
  {
    id: 1,
    RankName: "INTERN",
    stars: 0,
    club: 3,
  },
  {
    id: 2,
    RankName: "ASSOCIATE",
    stars: 3,
    club: 4,
  },
  {
    id: 3,
    RankName: "MANAGER",
    stars: 8,
    club: 5,
  },
  {
    id: 4,
    RankName: "SENIOR MANAGER",
    stars: 15,
    club: 6,
  },
  {
    id: 5,
    RankName: "VICE PRESIDENT",
    stars: 30,
    club: 7,
  },
  {
    id: 6,
    RankName: "CHIEF FINANCE OFFICER",
    stars: 56,
    club: 8,
  },
  {
    id: 7,
    RankName: "CHIEF MARKETING OFFICER",
    stars: 96,
    club: 9,
  },
  {
    id: 8,
    RankName: "CHIEF EXECUTIVE OFFICER",
    stars: 135,
    club: 10,
  },
];

const BecameYourOwnBoss = () => {
  const { clubCredits, userStars, userClubRank } = useContext(ClubContext);
  const { userCredits } = useContext(UserContext);
  const { motorCredits } = useContext(MotorContext);
  const { hotelCredits } = useContext(HotelContext);
  useTelegramBackButton();
  return (
    <div className="becomeBossMain">
      <div className="boss_ProfileBar">
        <div
          className="task_AmountSection"
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "15px",
          }}
        >
          <ProfileBar />
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={creditCoinGif} alt="" height="60px" width="60px" />
            <span className="task_amount">
              {formatNumberWithCommas(
                userCredits +
                  Math.floor(clubCredits) +
                  Math.floor(motorCredits) +
                  Math.floor(hotelCredits)
              )}
            </span>
          </div>
        </div>
      </div>
      {/* boss info section  */}
      <div className="boss_info">
        <span className="boss_info_title">BECOME YOUR OWN BOSS</span>
        <div className="boss_RankBox">
          <div className="rankBox_rankDiv">
            <div className="rankDiv_left">
              <span className="rankDivLeftTitle">Actual Rank:</span>
              <br />
              <span className="rankDivLeftSubTitle">
                {userClubToRankNameMapping[userClubRank]}
              </span>
            </div>

            <div className="rankDiv_Right">
              <span className="rankDivRightTitle">Next Rank:</span>
              <br />
              <span className="rankDivRightSubTitle">
                {userClubToRankNameMapping[userClubRank + 1]}
              </span>
            </div>
          </div>
          <div className="boss_ceoRank">
            <div className="CeoRankDiv">
              <div className="ceoRankInfo">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <FaStar color="#FFAE42" fontSize="25px" />
                  <p
                    style={{
                      fontSize: "15px",
                      margin: "0px",
                      fontWeight: "bolder",
                    }}
                  >
                    {userStars}/
                    {userClubRankToStarsMapping[userClubRank + 1] || 210}
                  </p>
                </div>
              </div>
              <div className="Playlevelscore">
                <div className="PlayprogressBox">
                  <div
                    className="PlaygreenBar"
                    style={{
                      // width: `${
                      //   ((user.nextRankUpAt - user.invitesToRankUp) /
                      //     user.nextRankUpAt) *
                      //   100
                      // }%`,
                      width: `${
                        (userStars * 100) /
                        (userClubRankToStarsMapping[userClubRank + 1] || 210)
                      }%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* boss table section */}
      <div className="boss_table_section">
        <div style={{ height: "70%", marginBottom: "20px" }}>
          <div style={{ height: "100%", overflowY: "auto" }}>
            <table style={{ width: "100%", tableLayout: "fixed" }}>
              <thead style={{ textAlign: "left" }}>
                <tr style={{ fontSize: "12px" }}>
                  <th style={{ padding: "15px" }}>RANK</th>
                  <th style={{ fontSize: "11px", padding: "15px 0px" }}>
                    STARS REQUIREMENTS
                  </th>
                  <th style={{ padding: "15px" }}>CLUB</th>
                </tr>
              </thead>
              <tbody>
                {rankTableData.map((item, index) => (
                  <tr
                    key={index}
                    style={{ textAlign: "left", fontSize: "10px" }}
                  >
                    <td
                      className={`${
                        index + 1 === userClubRank && "activeRank"
                      }`}
                    >
                      {item.RankName}
                    </td>
                    <td
                      className={`${
                        index + 1 === userClubRank && "activeRank"
                      }`}
                    >
                      {item.stars}
                    </td>
                    <td
                      className={`${
                        index + 1 === userClubRank && "activeRank"
                      }`}
                    >
                      {item.club} Club
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <BottomButtons />
      </div>
    </div>
  );
};

export default BecameYourOwnBoss;
