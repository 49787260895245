import React from "react";
import { useNavigate } from "react-router-dom";
import tableStepImg from "../../../media/v3_tableStep.png";
import "./airDrop.css";

const AirDropTableStep = () => {
  const navigate = useNavigate();
  return (
    <div className="airDropMainContainer">
      <div
        style={{
          position: "absolute",
          height: "130px",
          width: "100%",
          background:
            "linear-gradient(to top, rgba(0, 0, 0, 0.90) 20%, rgba(0, 0, 0, 0) 100%)",
          zIndex: 1,
          top: "30%",
          pointerEvents: "none",
        }}
      ></div>
      <div className="airDropImg_section">
        <div className="airDropCloseIcon">
          <span onClick={() => navigate("/?noload=true")}>CLOSE X</span>
        </div>
        <img src={tableStepImg} alt="" className="airDropTopImg" />
      </div>

      <div
        className="airDrop_Text_section"
        style={{
          zIndex: 101010,
          position: "absolute",
        }}
      >
        <div>
          <span
            onClick={() => navigate("/airdrop/learnMore")}
            style={{
              position: "absolute",
              fontSize: "25px",
              color: "black",
              background: "white",
              textAlign: "center",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "-35%",
              right: "3%",
              zIndex: 101010,
              border: "0.5px solid black",
            }}
          >
            ?
          </span>
          <button
            style={{
              background: "white",
              color: "black",
              fontWeight: "700",
              fontSize: "15px",
              padding: "5px 10px",
              outline: "none",
              border: "none",
              borderRadius: "20px",
              textAlign: "center",
              width: "200px",
            }}
          >
            YOUR AIRDROP
          </button>
          <div
            style={{
              textAlign: "center",
              marginTop: "5px",
            }}
          >
            <span
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Follow your Airdrop
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          margin: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            background: "rgba(42,99,244,1)",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            padding: "15px",
            fontWeight: 500,
          }}
        >
          <div>Amount Vested</div>
          <div>Amount Received</div>
          <div>$FRGB Price</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            background: "white",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            padding: "5px",
            fontWeight: 500,
            color: "black",
            height: "40px",
          }}
        >
          <div>25,224,003.12</div>
          <div
            style={{
              padding: "1px",
              background: "blue",
              height: "100%",
            }}
          ></div>
          <div>25,224,003.12</div>
          <div
            style={{
              padding: "1px",
              background: "blue",
              height: "100%",
            }}
          ></div>
          <div>25,224,003.12</div>
        </div>
      </div>
      <div
        style={{
          height: "40vh",
          overflowY: "scroll",
          background: "#D9D9D9",
          borderRadius: "10px",
          margin: "3px",
        }}
      >
        <div
          style={{
            position: "sticky",
            top: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            textAlign: "center",
            fontWeight: "normal",
            padding: "10px",
            background: "#ddd",
            zIndex: 1,
            color: "black",
          }}
        >
          <span>Airdrop</span>
          <span
            style={{
              textAlign: "left",
            }}
          >
            $FRGB Amount
          </span>
          <span>Date</span>
          <span>Status</span>
        </div>
        {Array.from({ length: 10 }).map((_, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
              padding: "10px",
              background: index < 2 ? "#CDF2BC" : "#BDBEBD94",
              borderBottom: "4px solid white",
              color: "black",
            }}
          >
            <span
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                color: "blue",
                background: "white",
                fontWeight: "bold",
                padding: "2px",
                textAlign: "center",
              }}
            >
              {index + 1}
            </span>
            <span
              style={{
                marginLeft: "45px",
              }}
            >
              25,232,923.77
            </span>
            <span>Dec 16, 2024</span>
            <span
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                background: index < 2 ? "#59d62e" : "gray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              {index < 2 ? "✓" : "✓"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AirDropTableStep;
