const getReadableNumber = (number) => {
  if (number) {
    let counter = 0;

    while (number > 1000 && counter < 3) {
      number = number / 1000;
      counter++;
    }

    let suffix = "";
    if (counter === 0) {
      suffix = "";
    } else if (counter === 1) {
      suffix = "K";
    } else if (counter === 2) {
      suffix = "M";
    } else {
      suffix = "B";
    }

    return number.toFixed(2) + suffix;
  } else {
    return "0";
  }
};

function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

const truncateName = (name, maxLength = 12) => {
  const nameStr = String(name); // Ensure name is a string
  if (nameStr.length <= maxLength) return nameStr;
  return `${nameStr.slice(0, maxLength)}...`;
};

function formatMinutes(minutes) {
  const MINUTES_IN_DAY = 1440; // 60 minutes * 24 hours
  const MINUTES_IN_HOUR = 60;

  if (minutes >= MINUTES_IN_DAY) {
    const days = Math.floor(minutes / MINUTES_IN_DAY);
    const remainingMinutes = minutes % MINUTES_IN_DAY;
    const hours = Math.floor(remainingMinutes / MINUTES_IN_HOUR);
    const mins = remainingMinutes % MINUTES_IN_HOUR;

    // Return in the format "12d 13h 20m"
    return `${days}d ${hours}h ${mins}m`;
  } else if (minutes >= MINUTES_IN_HOUR) {
    const hours = Math.floor(minutes / MINUTES_IN_HOUR);
    const mins = minutes % MINUTES_IN_HOUR;

    // Return in the format "2h 30m"
    return `${hours}h ${mins}m`;
  } else {
    // Return in the format "20m"
    return `${minutes}m`;
  }
}

module.exports = { getReadableNumber, getMobileOperatingSystem, truncateName, formatMinutes };
