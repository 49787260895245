import React from "react";
import { useNavigate } from "react-router-dom";
import airDropStep4 from "../../../media/v3_airDrop_step4.png";
import "./airDrop.css";
const LearnMore = () => {
  const navigate = useNavigate();
  return (
    <div className="airDropMainContainer">
      <div
        style={{
          position: "absolute",
          height: "130px",
          width: "100%",
          background:
            "linear-gradient(to top, #1D1C1D 20%, rgba(0, 0, 0, 0) 100%)",
          zIndex: 1,
          top: "32%",
          pointerEvents: "none",
        }}
      ></div>
      <div className="airDropImg_section">
        <div className="airDropCloseIcon">
          <span onClick={() => navigate("/?noload=true")}>CLOSE X</span>
        </div>
        <img src={airDropStep4} alt="" className="airDropTopImg" />
      </div>

      <div
        className="airDrop_Text_section"
        style={{
          zIndex: 101010,
          position: "absolute",
        }}
      >
        <div>
          <span
            onClick={() => navigate("/airdrop/learnMore")}
            style={{
              position: "absolute",
              fontSize: "25px",
              color: "black",
              background: "white",
              textAlign: "center",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "-35%",
              right: "3%",
              zIndex: 101010,
              border: "0.5px solid black",
            }}
          >
            ?
          </span>
          <button
            style={{
              background: "white",
              color: "black",
              fontWeight: "700",
              fontSize: "15px",
              padding: "5px 10px",
              outline: "none",
              border: "none",
              borderRadius: "20px",
              textAlign: "center",
              width: "230px",
            }}
          >
            LEARN MORE
          </button>
        </div>
      </div>

      <div class="airdrop-container">
        <span>
          To ensure you receive your airdrop, please follow these steps:
        </span>
        <ol class="airdrop-steps">
          <li>
            <strong>Create a Wallet with OKX Web3 Wallet:</strong>
            <ul>
              <li>Download the OKX Wallet from the Apple/Android Store.</li>
              <li>Choose “Create a new wallet” and set a strong password.</li>
              <li>
                Backup your seed phrase securely; this is crucial for wallet
                recovery.
              </li>
              <li>Your wallet is now ready to use.</li>
            </ul>
          </li>
          <li>
            <strong>OKX Telegram Wallet:</strong>
            <ul>
              <li>
                Open Telegram and search for <code>@OKX_WALLET_BOT</code>.
              </li>
              <li>Start the bot and select “Create Wallet.”</li>
              <li>Follow the prompts to set up your wallet.</li>
              <li>Ensure you securely backup your seed phrase.</li>
              <li>Your wallet is now set up within Telegram.</li>
            </ul>
            <p class="note">
              <strong>Note:</strong> The availability of OKX Web3 Wallet may
              vary by country. If it’s unavailable in your region, use the OKX
              Telegram Wallet.
            </p>
          </li>
          <li>
            <strong>Verify the Correct Wallet:</strong>
            <p>
              By default, our protocol will detect the correct Solana wallet
              once you connect either of the two wallets mentioned above.
            </p>
          </li>
          <li>
            <strong>Changing the Wallet:</strong>
            <p>You can change your wallet until December 14th, 11:00 AM CET.</p>
          </li>
          <li>
            <strong>Additional Actions to Receive the Airdrop:</strong>
            <p>
              No further action is required. Simply connecting your wallet is
              sufficient.
            </p>
          </li>
          <li>
            <strong>Airdrop Token Visibility:</strong>
            <p>
              Your tokens will be visible in the provided wallet on December
              16th.
            </p>
          </li>
          <li>
            <strong>Vesting Period:</strong>
            <p>
              Yes, there is a vesting period. Your allocation will be airdropped
              to your wallet at a rate of 10% every two weeks.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default LearnMore;
