import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WalletContext } from "../../../context/WalletContext";
import timeLineimg from "../../../media/airDropTimeLIne.png";
import airDropStep3 from "../../../media/v3_airDrop_step3.png";
import "./airDrop.css";
import { OKXSolanaProvider } from "@okxconnect/solana-provider";

const AirDropStepThree = () => {
  const navigate = useNavigate();
  const { activeWalletAddress, okxSolanaUI, saveSolanaAddresstobackend } = useContext(WalletContext);

  useEffect(() => {
    if(okxSolanaUI?.connected()) {
      let provider = new OKXSolanaProvider(okxSolanaUI);
      saveSolanaAddresstobackend(provider.getAccount().address);
    }
  }, [okxSolanaUI]);

  return (
    <div className="airDropMainContainer">
      <div
        style={{
          position: "absolute",
          height: "100px",
          width: "100%",
          background:
            "linear-gradient(to top, #1D1C1D 20%, rgba(0, 0, 0, 0) 100%)",
          zIndex: 1,
          top: "34%",
          pointerEvents: "none",
        }}
      ></div>
      <div className="airDropImg_section">
        <div className="airDropCloseIcon">
          <span onClick={() => navigate("/?noload=true")}>CLOSE X</span>
        </div>
        <img src={airDropStep3} alt="" className="airDropTopImg" />
      </div>

      <div
        className="airDrop_Text_section"
        style={{
          zIndex: 101010,
          position: "absolute",
        }}
      >
        <div>
          <span
            onClick={() => navigate("/airdrop/learnMore")}
            style={{
              position: "absolute",
              fontSize: "25px",
              color: "black",
              background: "white",
              textAlign: "center",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "-35%",
              right: "3%",
              zIndex: 101010,
              border: "0.5px solid black",
            }}
          >
            ?
          </span>
          <button
            style={{
              background: "white",
              color: "black",
              fontWeight: "700",
              fontSize: "15px",
              padding: "5px 10px",
              outline: "none",
              border: "none",
              borderRadius: "20px",
              textAlign: "center",
              width: "230px",
            }}
          >
            CONGRATULATION
          </button>
        </div>
      </div>
      <div
        style={{
          margin: "10px auto",
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 600,
          padding: "0px 10px",
          marginBottom: "2rem",
          marginTop: "1rem",
        }}
      >
        <span>
          Congratulation, you have successfully connected Solana Wallet
        </span>
      </div>

      <div
        style={{
          width: "90vw",
          margin: "10px auto",
          background: "white",
          textAlign: "center",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <div
          style={{
            background: "rgba(42, 99, 244, 1)",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            padding: "8px 0px",
            height: "30px",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              color: "white",
              fontFamily: "Roboto",
              letterSpacing: "0.5px",
            }}
          >
            MY SOLANA WALLET
          </span>
        </div>
        <div
          style={{
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            padding: "8px 0px",
            height: "30px",
            color: "#2A63F4",
            fontWeight: 600,
            fontFamily: "Roboto",
            letterSpacing: "0.5px",
            overflowWrap: "break-word",
          }}
        >
          <span>{activeWalletAddress}</span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          width: "95vw",
          marginTop: "-10px",
          marginRight: "-2px",
        }}
      >
        <div
          style={{
            borderBottomRightRadius: "12px",
            height: "30px",
            color: "white",
            fontWeight: 600,
            fontFamily: "Roboto",
            letterSpacing: "0.5px",
            background: "#2A63F4",
            width: "150px",
            textAlign: "center",
          }}
          onClick={async () => {
            if (okxSolanaUI.connected()) {
              await okxSolanaUI.disconnect();
              navigate("/airdrop/step2");
            }
          }}
        >
          CHANGE WALLET
        </div>
      </div>

      <div
        style={{
          width: "100vw",
          marginTop: "10px",
        }}
      >
        <img
          src={timeLineimg}
          alt=""
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
      </div>
      {/* <div
        style={{
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        <button
          onClick={() => navigate("/airdrop/followAirDrop")}
          style={{
            background: "#10E749",
            padding: "10px",
            borderRadius: "10px",
            fontSize: "15px",
            fontWeight: 700,
            color: "white",
            outline: "none",
            border: "none",
          }}
        >
          CHECK AIRDROP
        </button>
      </div> */}
    </div>
  );
};

export default AirDropStepThree;