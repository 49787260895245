import { default as React, useContext, useEffect, useState } from "react";
import { OKXSolanaProvider } from "@okxconnect/solana-provider";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import { WalletContext } from "../../../context/WalletContext";
import okxWalletImg from "../../../media/airDrop_okx_wallet.png";
import airDropStep2 from "../../../media/v3_airDrop_step2.png";
import "./airDrop.css";
import Confetti from "react-confetti";

const AirDropStepTwo = () => {
  const navigate = useNavigate();
  const { handleOkxSolanaConnect, okxSolanaUI, saveSolanaAddresstobackend } =
    useContext(WalletContext);
  const { user } = useContext(UserContext);
  const [showConfetti, setShowConfetti] = useState(false);
  const { jwt } = useContext(AuthContext);
  useEffect(() => {
    if (okxSolanaUI?.connected()) {
      navigate("/airdrop/step3");
    }
  }, [navigate, okxSolanaUI]);

  return (
    <div className="airDropMainContainer">
      <div
        style={{
          position: "absolute",
          height: "135px",
          width: "100%",
          background:
            "linear-gradient(to top, #1D1C1D 20%, rgba(0, 0, 0, 0) 100%)",
          zIndex: 1,
          top: "35%",
          pointerEvents: "none",
        }}
      ></div>

      <div className="airDropImg_section">
        <div className="airDropCloseIcon">
          <span onClick={() => navigate("/?noload=true")}>CLOSE X</span>
        </div>
        <img src={airDropStep2} alt="" className="airDropTopImg" />
      </div>
      <div
        className="airDrop_Text_section"
        style={{
          zIndex: 101010,
          position: "absolute",
        }}
      >
        <div style={{}}>
          <span
            onClick={() => navigate("/airdrop/learnMore")}
            style={{
              position: "absolute",
              fontSize: "25px",
              color: "black",
              background: "white",
              textAlign: "center",
              height: "30px",
              width: "30px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: "-50%",
              right: "3%",
              zIndex: 101010,
              border: "0.5px solid black",
            }}
          >
            ?
          </span>
          <button
            // onClick={() => navigate("/airdrop/step3")}
            style={{
              background: "white",
              color: "black",
              fontWeight: "700",
              fontSize: "16px",
              padding: "5px 10px",
              outline: "none",
              border: "none",
              borderRadius: "20px",
              textAlign: "center",
              width: "170px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              transition: "box-shadow 0.3s ease-in-out",
            }}
            onMouseOver={(e) => {
              e.target.style.boxShadow = "0px 8px 20px rgba(0, 0, 0, 0.2)";
            }}
            onMouseOut={(e) => {
              e.target.style.boxShadow = "0px 4px 12px rgba(0, 0, 0, 0.1)";
            }}
          >
            CONNECT
          </button>
        </div>
      </div>
      <div
        style={{
          margin: "10px auto",
          textAlign: "center",
          fontFamily: "Roboto",
          fontWeight: 600,
          marginTop: "4rem",
          position: "absolute",
          zIndex: 1,
        }}
      >
        <span>
          The Token will be listed on Solana, with Airdrop supported by OKX.
          Connect your wallet
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "45vh",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div className="airDrop_Card_section">
            <div
              style={{
                background: "white",
                width: "100%",
                height: "150px",
                borderRadius: "21px",
                border: "1px solid grey",
                overflow: "hidden",
                marginTop: "4rem",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "46%",
                  }}
                >
                  <img
                    src={okxWalletImg}
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "50%",
                    height: "100%",
                    paddingLeft: "10px",
                    paddingTop: "30px",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "roboto",
                      fontWeight: 700,
                      color: "black",
                    }}
                  >
                    CONNECT
                  </span>
                  <br />
                  <span
                    style={{
                      fontFamily: "roboto",
                      fontWeight: 700,
                      fontSize: "23px",
                      color: "black",
                    }}
                  >
                    OKX WALLET
                  </span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "end",
                      marginTop: "2rem",
                      marginRight: ".5rem",
                    }}
                  >
                    <button
                      onClick={async () => {
                        if (navigator.vibrate) {
                          navigator.vibrate(50);
                        } else if (window.Telegram?.WebApp) {
                          window.Telegram?.WebApp.HapticFeedback.notificationOccurred(
                            "success"
                          );
                        }

                        await handleOkxSolanaConnect();
                        setShowConfetti(true);

                        let provider = new OKXSolanaProvider(okxSolanaUI);
                        saveSolanaAddresstobackend(
                          provider.getAccount().address
                        );
                        setTimeout(() => {
                          navigate("/airdrop/step3");
                        }, 2500);
                      }}
                      style={{
                        background: "rgba(42, 99, 244, 1)",
                        color: "white",
                        outline: "none",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "13px",
                        width: "150px",
                        fontSize: "15px",
                      }}
                    >
                      Connect
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfetti && (
        <div className="confetti">
          <Confetti width={650} recycle={false} />
        </div>
      )}
      <div
        style={{
          textAlign: "center",
          padding: "5px",
        }}
      >
        <span>
          ENSURE YOUR SEED PHRASE IS SECURELY STORED AND KEPT CONFIDENCIAL AT
          ALL TIMES
        </span>
      </div>
    </div>
  );
};

export default AirDropStepTwo;
