import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import "../palay.css";
import Wallet from "../wallet";

import creditCoinIcon from "../../../media/silver-pepe-bg.png";
import blackTrophyIcon from "../../../media/v3_blackTrophyIcon.png";
import blackWalletIcon from "../../../media/v3_blackWalletIcon.png";
import newsIcon from "../../../media/v3_newsIcon.png";
import game1 from "../../../media/v3_scratchGame.png";
// rank images for background and tap

import rank1Tap from "../../../media/v3_rankBgTap/rank1TapImg.png";
import rank2Bg from "../../../media/v3_rankBgTap/rank2Bg.png";
import rank2Tap from "../../../media/v3_rankBgTap/rank2Tap.png";
import rank1Bg from "../../../media/v3_rankBgTap/v3_RANK_1Bg.png";

import rank3Bg from "../../../media/v3_rankBgTap/rank3Bg.png";
import rank3Tap from "../../../media/v3_rankBgTap/rank3Tap.png";

import rank4Bg from "../../../media/v3_rankBgTap/rank4Bg.png";
import rank4Tap from "../../../media/v3_rankBgTap/rank4Tap.png";

import rank5Bg from "../../../media/v3_rankBgTap/rank5Bg.png";
import rank5Tap from "../../../media/v3_rankBgTap/rank5Tap.png";

import rank6Bg from "../../../media/v3_rankBgTap/rank6Bg.png";
import rank6Tap from "../../../media/v3_rankBgTap/rank6Tap.png";

import rank7Bg from "../../../media/v3_rankBgTap/rank7Bg.png";
import rank7Tap from "../../../media/v3_rankBgTap/rank7Tap.png";

import blackMenuIcon from "../../../media/v3_menuBlackIcon.png";
import rank8Bg from "../../../media/v3_rankBgTap/rank8Bg.png";
import rank8Tap from "../../../media/v3_rankBgTap/rank8Tap.png";
// ===============================ends

import { animated, useSpring } from "@react-spring/web";
import TonConnect from "@tonconnect/sdk";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import v3_boostIcon from "../../../media/v3_boostIcon.png";
import v3_energyIcon from "../../../media/v3_energyIcon.png";
import v3_faqIcon from "../../../media/v3_faqIcon.png";
import v3_KycIcon from "../../../media/v3_KYCIcon.png";
import v3_miniGameIcon from "../../../media/v3_miniGameIcon.png";
import {
  energyRankMapping,
  userClubRankToStarsMapping,
  userClubToRankNameMapping,
} from "../../../utils/constants";
import { getReadableNumber } from "../../../utils/helpers";
import EnergyPoup from "../../Shared/EnergyPopup/EnergyPoup";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import "./style.css";

import Cookies from "js-cookie";
import { FaStar } from "react-icons/fa6";
import { MdDoubleArrow } from "react-icons/md";
import { ClubContext } from "../../../context/ClubContext.js";
import { HotelContext } from "../../../context/HotelContext.js";
import { MotorContext } from "../../../context/MotorContext.js";

import v3_gearIcon from "../../../media/v3_gearIcon.png";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas.js";
import { handleVibrateOnOnclick } from "../../../utils/Vibrate.js";
import ComingSoon from "../../component/common/ComingSoon.jsx";
import Congratulation from "../../component/common/Congratulation.jsx";
import ScratchGame from "../../Mini-Game/AvailableGame/ScratchGame.jsx";
import MiniGame2 from "../../Mini-Game/MiniGame2/MiniGame2.jsx";
import SettingsPopup from "../../Shared/ProfileBar/SettingsPopup.jsx";
import V3Popup from "../../Shared/v3Popup/V3Popup.jsx";
import WalletComponent from "../../wallet/Wallet.jsx";
import Faq from "../FaqModal/Faq";
import NotificationModal from "../NotificationModal/NotificationModal.jsx";
import Profile from "../ProfileModal/profile";
import Booster from "./Booster.js";

import airDropIcon from "../../../media/v3_airDropIcon.png";
import okxIcon from "../../../media/v3_okxIcon.png";
import DemoPartner from "../TasksModal/demoPartner/DemoPartner.jsx";
import OkxModal from "./OkxModal/OkxModal.jsx";

const manifestUrl =
  "https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json";

const tonConnect = new TonConnect({
  manifestUrl,
});

const rankBgImgs = [
  rank1Bg,
  rank2Bg,
  rank3Bg,
  rank4Bg,
  rank5Bg,
  rank6Bg,
  rank7Bg,
  rank8Bg,
];
const rankTapImgs = [
  rank1Tap,
  rank2Tap,
  rank3Tap,
  rank4Tap,
  rank5Tap,
  rank6Tap,
  rank7Tap,
  rank8Tap,
];

const Play = ({
  tap,
  setTap,
  setPages,
  tokenPrice,
  setLastTapAt,
  setFirstTapAt,
  firstTapAt,
  setShowConnectWallet,
  showConnectWallet,
  connectedAddress,
  setIsAnnouncement,
  newAnnouncement,
  blink,
}) => {
  const { user, setUser, userCredits, userEnergy, setUserEnergy } =
    useContext(UserContext);
  const { lastBoostAt, level, name, rank, dailyCount, energyCoolDownActive } =
    user;
  const [waves, setWaves] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showEnergyModal, setShowEnergyModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const MAX_ENERGY = energyRankMapping[rank];
  const userPepeCredits = getReadableNumber(user?.pepeCredits);
  const [showMore, setShowMore] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isGameOpen, setGameOpen] = useState(false);
  const [isKycOpen, setKycOpen] = useState(false);
  const [isWalletOpen, setWalletOpen] = useState(false);
  const [isBoosterOpen, setBoosterOpen] = useState(false);
  const [currNotification, setCurrNotification] = useState(null);
  const [isSettings, setIsSettings] = useState(false);
  const { clubCredits, userStars, userClubRank } = useContext(ClubContext);
  const { notification } = useContext(AuthContext);
  const { motorCredits } = useContext(MotorContext);
  const { hotelCredits } = useContext(HotelContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [availableGame, setAvailableGame] = useState(false);
  const [barWidth, setBarWidth] = useState(0);
  const [show, setShow] = useState(false);
  const [miniGameResult, setMiniGameResult] = useState("");
  const [congo, setCongo] = useState(false);
  const [updatedCredits, setUpdatedCredits] = useState();
  const [isPopAddModalOpen, setIsPopAddModalOpen] = useState(false);
  const [showPrivateSale, setShowPrivateSale] = useState(true);
  const [oksModal, setOksModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [claimStatuses, setClaimStatuses] = useState({});
  const [isPopupBitgetOpen, setPopupBitgetOpen] = useState(false);

  const handlePopupClose = () => {
    setPopupBitgetOpen(false);
  };

  const updateClaimStatus = (partnerId) => {
    setClaimStatuses((prevStatuses) => ({
      ...prevStatuses,
      [partnerId]: true, // Mark the claim as completed for the given partner
    }));
  };

  const partner = {
    partner_id: "102",
    partner_name: "Bitget Wallet",
    partner_description: "Your Web3 trading wallet for the future",
    partner_image_url: "../media/bitgetWallet.jpg",
    reward: 1000000,
    tasks: [
      {
        task_id: "1021",
        task_name: "Download bitget wallet task",
        task_url: "https://bitgetwallet.onelink.me/6Vx1/68tu4rdi",
        task_image: "download",
        label: "Download",
        credits: 0,
      },
      {
        task_id: "1022",
        task_name: "Join bitget wallet regional Channel",
        task_url: "https://t.me/+NSCNksY69gZiODY1",
        task_image: "telegram",
        label: "Join",
        credits: 0,
      },
      {
        task_id: "1023",
        task_name: "Follow bitget wallet regional X",
        task_url: "https://x.com/BitgetWalletSA",
        task_image: "tweeterIcon",
        label: "Follow",
        credits: 0,
      },
    ],
  };

  // handle POP ADD MODAL

  useEffect(() => {
    const lastShown = Cookies.get("lastModalShown");
    const now = new Date().getTime();

    const noload = queryParams.get("noload");
    if (!user?.isPremiumUser && noload !== "true") {
      setIsPopAddModalOpen(true);
    } else {
      if (!lastShown || now - lastShown > 24 * 60 * 60 * 1000) {
        setIsPopAddModalOpen(true);
        Cookies.set("lastModalShown", now, { expires: 1 });
      }
    }
  }, [user?.isPremiumUser]);
  const handlePOPAddModalClose = () => {
    setIsPopAddModalOpen(false);
  };
  // =================end============

  useEffect(() => {
    if (notification) setCurrNotification(notification.amount);
    const t1 = setTimeout(() => setCurrNotification(null), 4000);
    return () => clearTimeout(t1);
  }, [notification]);

  // Trigger the animation when the component mounts
  useEffect(() => {
    const finalWidth =
      (userStars * 100) / (userClubRankToStarsMapping[userClubRank + 1] || 210); // Calculate the width
    setTimeout(() => {
      setBarWidth(finalWidth);
    }, 100);
  }, [userStars, userClubRank]);
  const handleOpenButton = () => {
    setAvailableGame(true);
  };

  const handleNotification = () => {
    setIsNotification(false);
  };
  const handleBoosterClick = () => {
    setBoosterOpen(true);
  };

  const handleBoosterPopup = () => {
    setBoosterOpen(false);
  };

  const handleWalletClick = () => {
    setWalletOpen(true);
  };

  const handleWalletPopup = () => {
    setWalletOpen(false);
  };

  const handleKycClick = () => {
    setKycOpen(true);
  };

  const handleKycPopup = () => {
    setKycOpen(false);
  };

  const handleGameClick = () => {
    setGameOpen(true);
  };

  const handleGamePopup = () => {
    setGameOpen(false);
    setAvailableGame(false);
  };

  const handlePartnerClick = () => {
    setPopupOpen(true); // Open the popup
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleSettingClick = () => {
    setIsSettings(true);
  };

  const handleSettingPopup = () => {
    setIsSettings(false);
  };
  const handleOkxModalClose = () => {
    setOksModal(false);
  };

  const netPerTapEnergyLoss = level + 1;
  const walletRef = useRef();
  useEffect(() => {
    if (user && walletRef.current)
      walletRef.current.processPendingTransactions();
  }, [user]);

  useEffect(() => {
    setVisible(true);
  }, [user]);

  const handleTap = (e, outSite) => {
    if (userEnergy >= netPerTapEnergyLoss) {
      for (let touch of e.changedTouches) {
        const x_ani = touch.clientX;
        const y_ani = touch.clientY - 30;
        console.log(x_ani, y_ani);
        createAnimation(x_ani, y_ani); // +1, +2 animation
        setTap((tap) => tap + 1);
        setUserEnergy((energy) => energy - netPerTapEnergyLoss);
        const currTime = moment().utc().toISOString();
        setLastTapAt(currTime);
        if (!firstTapAt) setFirstTapAt(currTime);

        if (navigator.vibrate) {
          navigator.vibrate(50); // vibration on tap
        } else if (window.Telegram?.WebApp)
          window.Telegram?.WebApp.HapticFeedback.notificationOccurred(
            "success"
          );

        const rect = e.target.getBoundingClientRect();
        const x = touch.clientX - rect.left; // x position within the element
        const y = touch.clientY - rect.top; // y position within the element

        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const deltaX = x - centerX;
        const deltaY = y - centerY;

        // Calculate rotations based on click position
        const rotateX = (-deltaY / centerY) * 20; // Positive deltaY tilts the top part towards the user
        const rotateY = (deltaX / centerX) * 20; // Positive deltaX tilts the right part towards the user

        if (outSite === false) {
          // Apply the transformation
          e.target.style.transformOrigin = "center";
          e.target.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

          // Reset the transform after a short delay to create a bounce effect
          setTimeout(() => {
            e.target.style.transform = "rotateX(0) rotateY(0)";
          }, 300);
        }
      }
    }
  };

  const createAnimation = (x, y) => {
    const anime = document.createElement("span");
    anime.innerHTML = `+${level + 1}`;
    anime.className = "anime";
    anime.style.top = `${y}px`;
    anime.style.left = `${x}px`;
    anime.onclick = () => anime.remove();
    document.body.appendChild(anime);

    const animation = anime.animate(
      [
        { visibility: "visible" },
        // { transform: "scale(1.8);" },
        { visibility: "hidden" },
      ],
      { duration: 350 }
    );

    animation.onfinish = () => anime.click();
  };

  const energyAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(100%)",
      });
    },
    config: { duration: 300 },
  });
  const boostAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(100%)",
      });
    },
    config: { duration: 300 },
  });

  const RankAnimation = useSpring({
    from: { transform: "translateX(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(100%)",
      });
    },
    config: { duration: 400 },
  });
  const coinAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });

  const creditAnimation = useSpring({
    transform: toggle ? "scale(1.4)" : "scale(1)",
    marginLeft: toggle ? "20%" : "0px",
  });

  const profileBoxAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0.1 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      // await next({ backdropFilter: "blur(40px)" });
      // await next({ height: "132px" });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(100%)",
      });
    },
    config: { duration: 300 },
  });

  const handleConnect = () => {
    if (connectedAddress !== "") {
      navigate("/wallet");
    } else {
      setShowConnectWallet(!showConnectWallet);
    }
  };
  const [isZoomed, setIsZoomed] = useState(false);

  const toggleZoom = () => {
    setIsZoomed(true);
    setTimeout(() => {
      setIsZoomed(false);
    }, 100); // Adjust the time for zoom-out as needed
  };

  // =================ranks images and tap images
  const handlePopupOpen = (partner) => {
    setSelectedPartner(partner);
    setPopupBitgetOpen(true);
  };

  return (
    <div
      className="main-game"
      style={{
        backgroundImage: `url(${rankBgImgs[userClubRank - 1]})`,
        height: "100vh",
        width: "100vw",
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    >
      <div className="content">
        <div
          className={`newtap ${isZoomed ? "zoomed" : ""}`}
          onTouchStart={(e) => {
            handleTap(e, true);
            toggleZoom();
            setToggle(true);
            setTimeout(() => {
              setToggle(false);
            }, 90);
          }}
          onTouchMove={(e) => e.preventDefault()}
        >
          <img
            src={rankTapImgs[userClubRank - 1]}
            alt=""
            className="TapingImg"
          />
        </div>
        <div className="playFixedDiv">
          <Wallet ref={walletRef} name={name} />

          <div className="pfBar_pfx" style={{ marginTop: "10px" }}>
            <ProfileBar
              setIsSettings={setIsSettings}
              // currNotification={currNotification}
              currNotification={true}
              isSettingsShown={true}
            />
          </div>

          <div
            className="topCardDivMain"
            onTouchStart={(e) => {
              handleTap(e, false);
              setToggle(true);
              setTimeout(() => {
                setToggle(false);
              }, 90);
            }}
            style={{
              // background: "#FFFFFF66",
              position: "relative",
              width: "92%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* credit section */}
            <div className="topCardDiv">
              <div className="card2">
                <div className="topCardCoinDiv">
                  <img className="creditCoinImg" src={creditCoinIcon} alt="" />
                </div>
                <div
                  className="mytapcredBox"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <p className="total-credit-number">
                      <animated.div style={{ ...creditAnimation }}>
                        {formatNumberWithCommas(
                          userCredits +
                            tap * (level + 1) +
                            Math.floor(clubCredits) +
                            Math.floor(motorCredits) +
                            Math.floor(hotelCredits)
                        )}
                      </animated.div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* buttons */}

          {/* =================== */}
        </div>

        {/* Menu division */}
        <div id="outerContainer">
          <div
            id="container"
            className="dotButton"
            onClick={() => setShowMore(!showMore)}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <div className="red">1</div> */}
            <img
              onClick={() => handleVibrateOnOnclick()}
              src={blackMenuIcon}
              alt="Leader Board Animation"
              style={{
                objectFit: "contain",
                position: "relative",
                zIndex: "2",
                width: "50px",
                height: "50px",
              }}
            />
          </div>
        </div>

        <div
          className={`subButtonIconDiv ${showMore ? "open" : "closed"}`}
          style={{
            position: "absolute",
            top: "26.9%",
            right: "9.2%",
            width: "45px",
          }}
        >
          <div
            className="buttonChildDiv"
            style={{
              width: "100%",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={handleSettingClick}
          >
            <img
              onClick={() => handleVibrateOnOnclick()}
              src={v3_gearIcon}
              alt="icon"
              height="40px"
              width="40px"
              className="subIconImg"
            />
            <span>Settings</span>
          </div>

          <div
            className="buttonChildDiv"
            style={{
              width: "100%",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={handleGameClick}
          >
            <img
              onClick={() => handleVibrateOnOnclick()}
              src={v3_miniGameIcon}
              alt="icon"
              height="40px"
              width="40px"
              className="subIconImg"
            />
            <span>
              Mini <br />
              Game
            </span>
          </div>
          <div
            className="buttonChildDiv"
            style={{
              width: "100%",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => setIsNotification(true)}
          >
            <img
              onClick={() => handleVibrateOnOnclick()}
              src={newsIcon}
              alt="icon"
              height="40px"
              width="40px"
              className="subIconImg"
            />
            <span>News</span>
          </div>
          <div onClick={handleKycClick}>
            <img
              onClick={() => handleVibrateOnOnclick()}
              src={v3_KycIcon}
              alt="icon"
              height="40px"
              width="40px"
              className="subIconImg"
              style={{ marginLeft: "-4px", marginBottom: "-10px" }}
            />{" "}
            <br />
            <span style={{ fontSize: "11px" }}>KYC</span>
          </div>
          <div
            className="buttonChildDiv"
            style={{
              width: "100%",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={handlePartnerClick}
          >
            <img
              onClick={() => handleVibrateOnOnclick()}
              src={v3_faqIcon}
              alt="icon"
              height="40px"
              width="40px"
              className="subIconImg"
            />
            <span>FAQ</span>
          </div>
        </div>

        {/* wallet division */}
        {/* <div id="outerContainer_wallet">
          <div id="container" className="dotButton">
            <div
              onClick={handleWalletClick}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                onClick={() => handleVibrateOnOnclick()}
                src={blackWalletIcon}
                alt="Wallet Animation"
                style={{
                  objectFit: "cover",
                  position: "relative",
                  zIndex: "2",
                  width: "50px",
                  height: "50px",
                }}
              />
            </div>
          </div>
        </div> */}

        {/* Leader board division */}
        <div id="outerContainer_leader">
          <div
            id="container"
            className="dotButton"
            onClick={() => navigate("/stats")}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              onClick={() => handleVibrateOnOnclick()}
              src={blackTrophyIcon}
              alt="Menu Animation"
              style={{
                width: "50px",
                height: "50px",
              }}
            />
          </div>
        </div>

        {/* <div id="okxIconDiv">
          <div
            id=""
            className="dotButton"
            onClick={() => navigate("")}
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <span
              style={{
                position: "absolute",
                width: "65%",
                top: "-10%",
                right: "-10%",
                background: "red",
                borderRadius: "10px",
                fontSize: "9px",
              }}
            >
              new
            </span>
            <img
              onClick={() => {
                setOksModal(true);
                handleVibrateOnOnclick();
              }}
              src={okxIcon}
              alt="Menu Animation"
              style={{
                height: "45px",
                width: "45px",
                display: "flex",
                alignItems: "center",
              }}
            />
            <span
              style={{
                position: "absolute",
                bottom: "-10%",
                right: "5%",
                background: "white",
                color: "black",
                borderRadius: "4px",
                fontSize: "9px",
                width: "85%",
                fontWeight: 700,
                fontFamily: "Roboto",
              }}
            >
              OKX
            </span>
          </div>
        </div>
        <div id="bitgetDiv">
          <div
            id=""
            className="dotButton"
            onClick={() => navigate("")}
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <img
              onClick={() => handlePopupOpen(partner)}
              src={partner.partner_image_url}
              alt="Menu Animation"
              style={{
                width: "45px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
            />
            <span
              style={{
                position: "absolute",
                bottom: "-10%",
                right: "0%",
                background: "white",
                color: "black",
                borderRadius: "4px",
                fontSize: "8px",
                fontWeight: 800,
                width: "85%",
                padding: "2px",
                textAlign: "center",
                fontFamily: "Roboto",
              }}
            >
              BITGET
            </span>
          </div>
        </div> */}
        <div id="airDropDiv" onClick={() => handleVibrateOnOnclick()}>
          <div
            id="airDropContent"
            className="dotButton"
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              borderRadius: "4px",
            }}
          >
            <span
              style={{
                position: "absolute",
                width: "65%",
                top: "-10%",
                right: "-30%",
                background: "red",
                borderRadius: "10px",
                fontSize: "9px",
              }}
            >
              new
            </span>
            <img
              onClick={() => navigate("/airdrop")}
              src={airDropIcon}
              alt="Menu Animation"
              style={{
                width: "45px",
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
                background: "black",
              }}
            />
            <span
              style={{
                position: "absolute",
                bottom: "-30%",
                right: "0%",
                background: "white",
                color: "black",
                borderRadius: "4px",
                fontSize: "8px",
                fontWeight: 800,
                width: "85%",
                padding: "2px",
                textAlign: "center",
                fontFamily: "Roboto",
              }}
            >
              AIRDROP
            </span>
          </div>
        </div>

        <div className="CeoRankDiv">
          <div
            className="ceoRankInfo"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                width: "100%",
              }}
              onClick={() => navigate("/ceo")}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                onClick={() => navigate("/ceo")}
              >
                <FaStar color="#FFAE42" fontSize="25px" />
                <p
                  style={{
                    fontSize: "15px",
                    margin: "0px",
                    fontWeight: "bolder",
                  }}
                >
                  {userStars}/
                  {userClubRankToStarsMapping[userClubRank + 1] || 210}
                </p>
              </div>
              <div style={{ flexGrow: "1" }} />
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                onClick={() => navigate("/ceo")}
              >
                <p
                  onClick={() => handleVibrateOnOnclick()}
                  style={{
                    fontSize: "15px",
                    margin: "0px",
                    fontWeight: "bold",
                  }}
                >
                  {userClubToRankNameMapping[userClubRank]}
                </p>
                <MdDoubleArrow color="white" fontSize="25px" />
              </div>
            </div>
            <div className="Playlevelscore">
              <div className="PlayprogressBox">
                <div
                  className="PlaygreenBar"
                  style={{
                    // width: `${
                    //   ((user.nextRankUpAt - user.invitesToRankUp) /
                    //     user.nextRankUpAt) *
                    //   100
                    // }%`,
                    width: `${barWidth}%`,
                  }}
                ></div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                // marginBottom: "8px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
                onClick={handleBoosterClick}
              >
                <img
                  onClick={() => handleVibrateOnOnclick()}
                  src={v3_energyIcon}
                  alt="energy"
                  height="40px"
                  width="50px"
                />
                <p
                  style={{
                    fontSize: "15px",
                    margin: "0px",
                    fontWeight: "bolder",
                  }}
                >
                  {Math.floor(userEnergy)}/{MAX_ENERGY}
                </p>
              </div>
              <div style={{ flexGrow: "1" }} />
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
                onClick={handleBoosterClick}
              >
                <p
                  style={{
                    fontSize: "15px",
                    margin: "0px",
                    fontWeight: "bold",
                  }}
                >
                  BOOSTER
                </p>
                <img
                  src={v3_boostIcon}
                  alt="energy"
                  height="30px"
                  width="30px"
                />
              </div>
            </div>
          </div>
        </div>

        {showEnergyModal && (
          <EnergyPoup
            showEnergyModal={showEnergyModal}
            setShowEnergyModal={setShowEnergyModal}
          />
        )}
        {isPopupOpen && (
          // Render the Profile component if isPopupOpen is true
          <Faq
            isOpen={isPopupOpen} // Pass isOpen to control the Popup
            onClose={handleClosePopup} // Pass the close function
          />
        )}
        <V3Popup isOpen={isGameOpen} onClose={handleGamePopup}>
          <div style={{ padding: "25px" }}>
            {availableGame ? (
              <MiniGame2
                handleGamePopup={handleGamePopup}
                show={show}
                setShow={setShow}
                setMiniGameResult={setMiniGameResult}
                congo={congo}
                setUpdatedCredits={setUpdatedCredits}
              />
            ) : (
              <>
                <ScratchGame
                  isAvailable={true}
                  gameBanner={game1}
                  handleOpenButton={handleOpenButton}
                />
                <ComingSoon />
              </>
            )}
          </div>
        </V3Popup>
        {/* show */}
        <V3Popup isOpen={show} onClose={() => setShow(false)} congo={congo}>
          <Congratulation
            onClose={() => setShow(false)}
            Result={miniGameResult}
            congo={congo}
            setCongo={setCongo}
            updatedCredits={updatedCredits}
          />
        </V3Popup>

        {isKycOpen && (
          <Profile
            isOpen={true} // Pass isOpen to control the Popup
            onClose={handleKycPopup}
          />
        )}

        {isWalletOpen && (
          <WalletComponent
            isOpen={isWalletOpen} // Pass isOpen to control the Popup
            onClose={handleWalletPopup}
          />
        )}

        {isBoosterOpen && (
          <Booster
            isOpen={isBoosterOpen} // Pass isOpen to control the Popup
            onClose={handleBoosterPopup}
          />
        )}
        {isNotification && (
          <NotificationModal
            isOpen={isNotification}
            onClose={handleNotification}
          />
        )}
        {isSettings && (
          <SettingsPopup isOpen={isSettings} onClose={handleSettingPopup} />
        )}

        {/* <V3Popup isOpen={isPopAddModalOpen} onClose={handlePOPAddModalClose}>
          <PopAddModal />
        </V3Popup> */}
        <V3Popup isOpen={oksModal} onClose={handleOkxModalClose}>
          <OkxModal />
        </V3Popup>

        <V3Popup isOpen={isPopupBitgetOpen} onClose={handlePopupClose}>
          <DemoPartner
            partner={selectedPartner}
            onClose={handlePopupClose}
            updateClaimStatus={updateClaimStatus}
            isBitgit={true}
          />{" "}
          {/* Pass the selected partner */}
        </V3Popup>
      </div>
    </div>
  );
};

export default Play;
