import React, { useContext, useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import airDropTimeLine from "../../../media/airDropTimeLIne.png";
import useTelegramBackButton from "../../../utils/backButton";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import TonComponent from "../../component/common/TonComponent";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import V3Popup from "../../Shared/v3Popup/V3Popup";
import BuyCoffeModal from "./BuyCoffeModal";
import "./privatesale.css";

const PrivateSale = ({ userID, setIsOpen, setUserCredits }) => {
  useTelegramBackButton();
  const [page, setPages] = useState("friend");
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [showTonBox, setShowTonBox] = useState(true);
  const [totalUser, setTotalUsers] = useState(2350);
  const [totalUserGame, setTotalUsersGame] = useState(2350);
  const [userPackages, setUserPackages] = useState([]);
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [totalTransactions, setTotalTransactions] = useState([]);
  const [packages, setPackages] = useState([]);
  const [userTransactions, setUserTransactions] = useState([]);
  const [successTransactionCount, setSuccessTransactionCount] = useState(0);
  const [tab, setTab] = useState("user");
  const [activePackage, setActivePackage] = useState("ton");
  const [sol, setSol] = useState(650.3);
  const [ton, setTon] = useState(15523.2);
  const [clickedBuy, setClickedBuy] = useState(false);
  const [packageID, setPackageID] = useState(0);
  const [isTransactionProcessing, setIsTransactionProcessing] = useState(false);

  // ===========
  const currentPrice = useRef(0);
  const twentyHourPrice = useRef(0);
  const thirtyDayPrice = useRef(0);
  const ytdPrice = useRef(0);
  const lpSolPrice = useRef(0);
  const usdTonPrice = useRef(0);
  const userDetails = useContext(UserContext);
  const { jwt } = useContext(AuthContext);

  useEffect(() => {
    async function totalUsersDapp() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/total-users`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();

        setTotalUsers(parsedResponse.totalUsers);
      } catch (error) {
        console.log(error);
      }
    }

    async function totalUsersGame() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/appmetric/packageOwners`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setTotalUsersGame(parsedResponse.data.value);
      } catch (error) {
        console.log(error);
      }
    }

    async function tokenPriceVariation() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/token-variation`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        // currentPrice.current = parsedResponse.result.currentPrice;
        twentyHourPrice.current = parsedResponse.result.twentyFourPrice;
        thirtyDayPrice.current = parsedResponse.result.thirtyDayPrice;
        ytdPrice.current = parsedResponse.result.YTDPrice;
      } catch (error) {
        console.log(error);
      }
    }

    async function solPrice() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/public-token-price`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setSol(parsedResponse.solPrice);
        console.log("lpSolBalance:", parsedResponse.lpSolBalance);
        lpSolPrice.current = parsedResponse.lpSolBalance;
        currentPrice.current = parsedResponse.tokenPrice;
      } catch (error) {
        console.log(error);
      }
    }
    async function tonPrice() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/appmetric/packages/value`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setTon(parsedResponse.data.balanceTon);
        usdTonPrice.current = parsedResponse.data.usdValue;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchTotalTransactions() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/transaction/admin?limit=5`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const parsedResponse = await response.json();
        setTotalTransactions(parsedResponse.data);
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchUserTransactions() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/transaction/admin/user/${userDetails.user.userID}?limit=5`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        const parsedResponse = await response.json();
        setUserTransactions(parsedResponse.data);
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchSuccessTransactionCount() {
      try {
        const response = await fetch(
          `https://game-prodapi.pepefrogbar.com/v1/transaction/successcount`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setSuccessTransactionCount(parsedResponse.count);
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchPackages() {
      try {
        const res = await fetch(`${SERVER_ENDPOINT}/v1/package`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const parsedRes = await res.json();
        if (parsedRes.status === "SUCCESS") setPackages(parsedRes.data);
        else throw new Error("Failed to fetch packages");
      } catch (error) {
        console.log(error);
      }
    }

    fetchPackages();
    fetchSuccessTransactionCount();
    fetchUserTransactions();
    solPrice();
    tonPrice();
    tokenPriceVariation();
    totalUsersDapp();
    totalUsersGame();
    fetchTotalTransactions();
  }, []);
  const handleImageLoad = () => {
    setLoaded(true);
  };
  const goBack = () => {
    navigate("/?noload=true");
  };

  useEffect(() => {
    setPages("privateSale");
    async function fetchPackages() {
      try {
        const res = await fetch(`${SERVER_ENDPOINT}/v1/package`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const parsedRes = await res.json();
        if (parsedRes.status === "SUCCESS") setUserPackages(parsedRes.data);
        else throw new Error("Failed to fetch packages");
      } catch (error) {
        console.log(error);
      }
    }
    fetchPackages();
  }, []);

  const tableData = [
    {
      id: 1,
      packageName: "ESPRESSO",
      tonAmount: "$30",
      starAmount: 1000,
    },
    {
      id: 2,
      packageName: "CAPPUCCINO",
      tonAmount: "$50",
      starAmount: 2500,
    },
    {
      id: 3,
      packageName: "MACCHIATO",
      tonAmount: "$100",
      starAmount: 5000,
    },
    {
      id: 4,
      packageName: "ICE COFFEE",
      tonAmount: "$200",
      starAmount: 10000,
    },
    {
      id: 5,
      packageName: "CAFFE MOCHA",
      tonAmount: "$500",
      starAmount: 22500,
    },
  ];

  const handleBuyModalClose = () => {
    setClickedBuy(false);
  };

  async function handleStarBuy(item) {
    try {
      console.log("item", item);
      const response = await fetch(`${SERVER_ENDPOINT}/v1/telegram/payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          packageName: item.packageName,
          stars: item.starAmount,
        }),
      });
      const parsedRes = await response.json();
      // alert("Payment process initiated. Please complete it in Telegram.");
      // console.log("response", parsedRes);
      const link = parsedRes.data.result;
      if (parsedRes.data.ok) {
        window.location.href = link;
      } else {
        throw new Error("Payment link not created successfully.");
      }
    } catch (error) {
      console.error("Payment initiation error:", error);
      alert("Failed to initiate payment.");
    }
  }

  return (
    <>
      <div className="privateSaleContainer">
        <div>
          <div className="privetSaleTopsection">
            <div className="pfBar_pfx" style={{ marginTop: "10px" }}>
              <ProfileBar />
            </div>
            <div className="privetSaleTop_content">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginTop: "-12px",
                }}
              >
                <span
                  style={{
                    fontSize: "29px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  PEPE’S FROGBAR
                </span>
              </div>
              <div style={{ marginTop: "-5px" }}>
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    color: "#BFA75D",
                  }}
                >
                  Private Sale
                </span>
              </div>
              {/* <div className="privetSaleCount">
                ${currentPrice.current.toFixed(10)}
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "150px",
                  height: "10px",
                  background:
                    "linear-gradient(90deg, rgb(20 20 20 / 19%) 0%, rgb(65, 65, 65) 99.76%, rgb(122, 122, 122) 100%)",
                  padding: "10px",
                  borderRadius: "10px",
                  border: "1px solid #BFA75D",
                  marginBottom: "10px",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                      color: "#c9c9c9",
                    }}
                  >
                    24H
                  </span>
                  <span
                    style={{
                      color: "#62D838",
                      fontSize: "10px",
                      letterSpacing: "1px",
                    }}
                  >
                    <IoTriangleSharp />
                    {(
                      ((currentPrice.current - twentyHourPrice.current) /
                        twentyHourPrice.current) *
                      100
                    ).toFixed(2)}
                    %{" "}
                  </span>
                </div>{" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                      color: "#c9c9c9",
                    }}
                  >
                    YTD
                  </span>
                  <span
                    style={{
                      color: "#62D838",
                      fontSize: "10px",
                      letterSpacing: "1px",
                    }}
                  >
                    <IoTriangleSharp />
                    {(
                      ((currentPrice.current - 0.0000045) / 0.0000045) *
                      100
                    ).toFixed(2)}
                    %
                  </span>
                </div>
              </div> */}
            </div>
            <div style={{ marginTop: "20px" }}>
              <TonComponent setTokenPrice={setTokenPrice} />
            </div>
          </div>

          {/* <div className="Ton_and_Tg">
            <div
              onClick={() => setActivePackage("ton")}
              className={`TonPackage ${
                activePackage === "ton" && "activePackageButton"
              }`}
            >
              <span>TON</span>
              <img src={tonLpIcon} alt="" height="25px" width="25px" />
            </div>
            <div
              onClick={() => setActivePackage("tg")}
              className={`tgPackage ${
                activePackage === "tg" && "activePackageButton"
              }`}
            >
              <span>TELEGRAM STARS</span>
              <img src={v3_star} alt="" height="25px" width="25px" />
            </div>
          </div> */}

          {/* <div className="packageTable" style={{ margin: "10px 10px" }}>
            <table>
              <thead
                style={{
                  border: "none",
                  borderBottom: "2px solid",
                }}
              >
                <th
                  style={{
                    width: "150px",
                    padding: "10px 0px",
                    paddingLeft: "10px",
                    textAlign: "left",
                  }}
                >
                  PACKAGE
                </th>
                <th>
                  {activePackage == "tg" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "50px",
                        paddingLeft: "15px",
                      }}
                    >
                      <span>STAR</span>
                      <img src={starIcon} alt="" height="25px" width="25px" />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "50px",
                        paddingLeft: "15px",
                      }}
                    >
                      <span>TON</span>
                      <img src={tonLpIcon} alt="" height="25px" width="25px" />
                    </div>
                  )}
                </th>
                <th></th>
              </thead>
              <tbody>
                {tableData.map((item, idx) => (
                  <tr
                    key={idx}
                    style={{
                      border: "none",
                      padding: "0px",
                      borderBottom: "1px solid rgba(137, 130, 130, 0.42)",
                    }}
                  >
                    <td
                      style={{
                        border: "none",
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "#c9c9c9",
                        padding: "0px",
                        paddingLeft: "10px",
                      }}
                    >
                      {item.packageName}
                    </td>
                    <td
                      style={{
                        border: "none",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#c9c9c9",
                      }}
                    >
                      {activePackage == "tg" ? item.starAmount : item.tonAmount}
                    </td>
                    <td
                      style={{ border: "none" }}
                      onClick={() => {
                        if (activePackage !== "tg") {
                          setClickedBuy(true);
                          setPackageID(idx);
                        }
                      }}
                    >
                      {activePackage == "tg" ? (
                        <img
                          alt=""
                          src={comingSoonIcon}
                          width={"150px"}
                          height={"30px"}
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        // </button>
                        // <img
                        //   alt=""
                        //   src={buyNowIcon}
                        //   width={"150px"}
                        //   height={"30px"}
                        //   style={{ objectFit: "cover" }}
                        // />
                        <button
                          disabled
                          style={{
                            background:
                              "linear-gradient(90deg, #ff0000, #ff6347)",
                            padding: "7px",
                            borderRadius: "14px",
                            color: "white",
                            outline: "none",
                            border: "none",
                            width: "110px",
                            fontSize: "13px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            boxShadow: "0px 4px 8px rgba(255, 0, 0, 0.4)",
                            transition: "all 0.3s ease",
                            letterSpacing: "1px",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.transform = "scale(1.05)")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.transform = "scale(1)")
                          }
                        >
                          Closed
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
          {/* ============= */}

          {/* <div className="historySection">
            <div
              onClick={() => setTab("user")}
              className={`purchase ${tab === "user" && "active"}`}
            >
              Purchase History
            </div>
            <div
              onClick={() => setTab("total")}
              className={`purchase ${tab === "total" && "active"}`}
            >
              Latest Transactions
            </div>
          </div> */}
          <div style={{}}>
            <div
              className="listing_soon"
              style={{
                background: "white",
                color: "red",
                fontWeight: 800,
                padding: "2px",
              }}
            >
              <Marquee gradient={false} speed={50} pauseOnHover>
                <span className="listing_soon_text" style={{ height: "2rem" }}>
                  PRIVATE SALE CLOSED ~ PRIVATE SALE CLOSED
                </span>
              </Marquee>
            </div>
            <img
              src={airDropTimeLine}
              alt="privet sale closed"
              style={{
                height: "90%",
                width: "100%",
                marginTop: "10px",
              }}
            />
            <div
              style={{
                textAlign: "center",
              }}
            >
              <button
                style={{
                  background: "linear-gradient(90deg, #008000, #90ee90)",
                  padding: "7px",
                  borderRadius: "5px",
                  color: "white",
                  outline: "none",
                  border: "none",
                  fontSize: "12px",
                  cursor: "pointer",
                  boxShadow: "0px 4px 8px #008000",
                  transition: "all 0.3s ease",
                  letterSpacing: "1px",
                  width: "120px",
                  fontWeight: "bold",
                  marginTop: "2rem",
                }}
                onClick={() => navigate("/airdrop")}
              >
                AIR DROP
              </button>
            </div>
          </div>
        </div>

        <V3Popup
          isOpen={clickedBuy}
          onClose={handleBuyModalClose}
          isTransactionProcessing={isTransactionProcessing}
          inCreaseHight={true}
        >
          <BuyCoffeModal
            packageID={packageID}
            handleCoffeModalClose={handleBuyModalClose}
            userPackages={userPackages}
            setIsTransactionProcessing={setIsTransactionProcessing}
          />
        </V3Popup>
      </div>
      <BottomButtons />
    </>
  );
};

export default PrivateSale;
